import { InputMail } from "@sapians/AssetAssessmentForm/Landing/InputMail/mod.ts";
import { ButtonBegin } from "@components/Button/ButtonCustom/ButtonBegin/mod.ts";
import { JSX, useRef, useSignal } from "@components/deps.ts";
import { Helper } from "@services/Helper.ts";
import { DialogConfirmMailRestoreSent } from "@sapians/AssetAssessmentForm/Landing/Dialog/DialogConfirmMailRestoreSent.tsx";
import { InputFormTypeTel } from "@sapians/AssetAssessmentForm/Form/mod.ts";

const EmailFormIsland = () => {
  const signalEmailValue = useSignal("");
  const signalFormResponses = useSignal({} as Record<string, string>);
  const signalFormErrors = useSignal({} as Record<string, boolean>);
  const formRef = useRef(null);
  const isDialogOpened = useSignal(false);

  const signalIsFormReadyToSent = useSignal(false);

  const onErrorCallback = (
    id: string,
    value: string,
  ) => {
    signalFormErrors.value[id] = true;
    signalFormResponses.value[id] = value;
    signalIsFormReadyToSent.value = false;
  };

  const onSuccessCallback = (
    id: string,
    value: string,
  ) => {
    delete signalFormErrors.value[id];
    signalFormResponses.value[id] = value;

    if (Object.keys(signalFormErrors.value).length === 0) {
      // only email field is required. 
      signalIsFormReadyToSent.value = signalFormResponses.value["email"] ? true : false;
    }
  };

  const onKeyDownHandler = (
    _: JSX.TargetedEvent<HTMLInputElement, KeyboardEvent>,
  ) => {
    // nothing todo
  };

  const handleDialogDisplay = () => {
    isDialogOpened.value = !isDialogOpened.value;
  };

  const handleFormSubmission = async () => {
    const email = formRef.current["email"].value;
    let phone = ""

    if (formRef.current["phone"].value) {
      phone = formRef.current["phone"].value;
    }

    const body = await Helper.postData("/", {
      email,
      phone,
    });

    if (body.exist) {
      //disable start button
      // signalIsFormReadyToSent.value = false;
      isDialogOpened.value = true;
      signalEmailValue.value = body.email;
      return;
    }

    if (globalThis.sessionStorage) {
      window.sessionStorage.setItem("PHONE", phone);
      window.sessionStorage.setItem("EMAIL", email);

      if (body.uniqueId === "no unique_id") {
        window.location.href = "/";
      } else {
        window.sessionStorage.setItem(
          "UNIQUEID",
          body.uniqueId,
        );
      }
    }

    if (globalThis.location) {
      window.location.href = body.url.form;
    }
  };

  /**
   * Prevent form submission from unexected user click   multiple times. 
   * @param handler implement form submission logic
   * @param delay remaining time before fire event 
   */
  const debounce = (handler: () => Promise<void>, delay: number = 500) => {
    let timeout: null | number = null;

    return () => {
      clearTimeout(timeout as number);
      timeout = setTimeout(async () => {
        await handler();
      }, delay);
    };
  };

  const submitForm = debounce(handleFormSubmission);

  const onSubmitHandler = (
    event: JSX.TargetedEvent<HTMLFormElement, Event>,
  ) => {
    event.preventDefault();
    if (signalIsFormReadyToSent.value) {
      submitForm();
    }
  };

  return (
    <>
      <form
        action={"/"}
        method={"POST"}
        className={"flex flex-col gap-l"}
        onSubmit={onSubmitHandler}
        ref={formRef}
      >
        <div>
          <InputMail
            name={"email"}
            id={"email"}
            isFocus={true}
            isDisabled={false}
            isRequired={true}
            placeholder={"nom.prenom@domaine.com"}
            value={signalEmailValue.value}
            state={"default"}
            onError={onErrorCallback}
            onSuccess={onSuccessCallback}
            onKeyDown={onKeyDownHandler}
          />
          <InputFormTypeTel
            label={"Votre numéro de téléphone"}
            name={"phone"}
            id={"phone"}
            isDisabled={false}
            value={""}
            placeholder={"+33 6 12 34 56 78"}
            state={"default"}
            onError={onErrorCallback}
            onSuccess={onSuccessCallback}
          />
        </div>
        <ButtonBegin
          isReady={signalIsFormReadyToSent.value}
        />
      </form>
      <DialogConfirmMailRestoreSent
        isOpened={isDialogOpened.value}
        mail={signalEmailValue.value}
        toggleCallback={() => handleDialogDisplay()}
      />
    </>
  );
};

export default EmailFormIsland;
