import { Button } from "../../Default/Button.tsx";
import { ButtonBeginPropsType } from "./types.ts";
import { IconArrowRight, IconSpacer } from "../../../Icon/mod.ts";
import { definition } from "../../../definition.ts";

export const ButtonBegin = (
  {
    onClick,
    isReady = false,
  }: ButtonBeginPropsType,
) => {
  let trailingIcon = null;
  let leadingIcon = null;
  if (isReady) {
    trailingIcon = <IconArrowRight size={"m"} />;
    leadingIcon = (
      <IconSpacer
        size={"m"}
      />
    );
  }
  return (
    <Button
      type={"submit"}
      variant={isReady ? "accent" : "primary"}
      onClick={onClick}
      trailingIcon={trailingIcon}
      leadingIcon={leadingIcon}
    >
      Commencer
    </Button>
  );
};
