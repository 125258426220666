import { BaseDialog, BaseDialogHeader } from "@components/BaseDialog/mod.ts";
import { DialogConfirmMailRestoreSentPropsType } from "./types.ts";

export const DialogConfirmMailRestoreSent = ({
  isOpened = false,
  mail = "",
  toggleCallback = undefined,
}: DialogConfirmMailRestoreSentPropsType) => {
  return (
    <BaseDialog
      title={"Saisie en cours détectée ..."}
      isOpened={isOpened}
      toggleCallback={toggleCallback}
      buttonsContent={[
        {
          text: "Ok",
        },
      ]}
    >
      Un lien vous permettant de retrouver votre saisie vient d'être envoyé par
      e-mail sur l'adresse{" "}
      <b>{mail}</b>. Si vous ne l'avez pas reçu, pensez à vérifier dans vos
      courriers indésirables.
    </BaseDialog>
  );
};
